import React, { useState, useEffect, lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import { useRoutes } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import AuthenticationRoutes from './AuthenticationRoutes';
import LoginRoutes from './LoginRoutes';
import EndUserRoutes from './endUserRoutes';
import MainRoutes from './MainRoutes';
import ExternalRoutes from './ExternalRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user } = useAuth();
    const [userType, setUserType] = useState('');
    const [tempArray, setTempArray] = useState([LoginRoutes]);

    useEffect(() => {
        if (user) {
            setUserType(user.userType);
        }
    }, [user]);

    useEffect(() => {
        // Move the state updates inside a separate useEffect
        switch (userType) {
            case 'External User':
                setTempArray([LoginRoutes, ExternalRoutes, AuthenticationRoutes]);
                break;
            case '':
                setTempArray([LoginRoutes, ExternalRoutes, AuthenticationRoutes]);
                break;
            case 'Super Administrator':
            case 'Admin User':
                setTempArray([LoginRoutes, MainRoutes, AuthenticationRoutes]);
                break;
            case 'System User':
                setTempArray([LoginRoutes, EndUserRoutes, AuthenticationRoutes]);
                break;
            default:
                break;
        }
    }, [userType]);

    // Render the routes based on the updated tempArray
    return useRoutes(tempArray);
}
