import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// external review modules
const ExternalReviewerProjectList = Loadable(
    lazy(() => import('../views/main-component/common/project/external-review/externalReviewerProjectList'))
);
const UserIndexPage = Loadable(lazy(() => import('../views/main-component/common/user-account/Index')));
const ProjectNewComponent = Loadable(lazy(() => import('../views/main-component/common/project/index')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/ExternalReviewerProjectList',
            element: <ExternalReviewerProjectList />
        },
        {
            path: '/userIndex',
            element: <UserIndexPage />
        },
        {
            path: '/viewProject',
            element: <ProjectNewComponent />
        }
    ]
};

export default MainRoutes;
