/**
 * axios setup to use mock service
 */
import { simulateAJAXStart, simulateAJAXEnd } from './util';
import axios from 'axios';

axios.defaults.withCredentials = true;

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL });

axiosServices.interceptors.request.use(
    (config) => {
        if (config.method !== 'get') simulateAJAXStart();
        return config;
    },
    (error) => Promise.reject(error)
);
// interceptor for http
axiosServices.interceptors.response.use(
    (response) => {
        if (response.data.redirect === true) {
            response.data.redirect = false;
            localStorage.clear();
            localStorage.removeItem('serviceToken');
            window.location.href = '/login';
            return Promise.reject(new Error('Redirecting to login'));
        }
        if (response.config.method !== 'get') {
            simulateAJAXEnd();
        }
        return response;
    },
    (error) => Promise.reject((error.response && error.response.data) || new Error('Wrong Services'))
);

export default axiosServices;
