// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import customerReducer from './slices/customer';
import userReducer from './slices/user';
import masterReducer from './slices/master';
import templateReducer from './slices/template';
import JWTContext from '../contexts/JWTContext';
import workflowReducer from './slices/workflow';
import kanbanReducer from './slices/kanban';
import projectReducer from './slices/project';
import otherReducer from './slices/other';
import importExportReducer from './slices/importExport';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    customer: customerReducer,
    user: userReducer,
    master: masterReducer,
    template: templateReducer,
    login: JWTContext,
    workflow: workflowReducer,
    kanban: kanbanReducer,
    project: projectReducer,
    other: otherReducer,
    importExport: importExportReducer
});
export default reducer;
