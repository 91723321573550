import React, { useEffect, useState } from 'react';
import Tnotification from './Tnotification';
import { getNotification } from 'store/slices/other';
import { useDispatch, useSelector } from 'store';

const Notification = ({ handleClose }) => {
    const dispatch = useDispatch();
    const { allNotificationList } = useSelector((state) => state.other);
    const [notificationData, setNotificationData] = useState([]);
    useEffect(() => {
        dispatch(getNotification());
    }, []);
    useEffect(() => {
        const temp = allNotificationList.map((item, index) => {
            const { subject, content, createdDate, catagory } = item;
            return { subject, content, createdDate, catagory };
        });
        setNotificationData(temp);
    }, [allNotificationList]);
    return <Tnotification data={notificationData} handleClose={handleClose} />;
};
export default Notification;
