// third-party
import { createSlice } from '@reduxjs/toolkit';
import { openSnackbar } from './snackbar';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    columnsDetails: [],
    csvDataSubmitResponse: []
};

const slice = createSlice({
    name: 'importExport',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET master DETAILS
        getColumnData(state, action) {
            state.columnsDetails = action.payload;
        },
        setUserDeailsSubmitResponse(state, action) {
            state.csvDataSubmitResponse = action.payload;
        },
        clearsubmitDetailsSuccess(state) {
            state.csvDataSubmitResponse = [];
        },
        clearBulkUploadError(state) {
            state.error = null;

            state.csvDataSubmitResponse = [];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const { clearBulkUploadError, clearsubmitDetailsSuccess } = slice.actions;

export function getColumnData() {
    return async () => {
        try {
            const response = await axios.get(`/getAllColumn`);
            dispatch(slice.actions.getColumnData(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function submitCsvData(data) {
    return async () => {
        try {
            const response = await axios.post(`/addCsvDetails`, data);
            if (response.data) {
                dispatch(slice.actions.setUserDeailsSubmitResponse(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function ImportUsers(fileData, type) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            const formData = new FormData();
            formData.append('file', fileData);
            formData.append('type', type);
            const response = await axios.post(`/ImportUsers`, formData);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}
