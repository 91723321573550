// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from './snackbar';
// ----------------------------------------------------------------------

const initialState = {
    error: null,
    templateList: [],
    templateData: [],
    projectCreationUserData: [],
    projectFundingData: [],
    projectApprovalData: [],
    projectExtensionData: [],
    projectClosureData: [],
    projectApprovalForm: [],
    templateResponse: {},
    templateId: [89]
};

const slice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // SET TEMPLATE LIST
        setTemplateList(state, action) {
            state.templateList = action.payload;
        },
        // SET TEMPLATE DETAILS
        setTemplateDetails(state, action) {
            state.templateData = action.payload;
        },
        getProjectCreationUserData(state, action) {
            state.projectCreationUserData = action.payload;
        },
        postProjectFundingData(state, action) {
            state.projectFundingData = action.payload;
        },
        getProjectExtensionData(state, action) {
            state.projectExtensionData = action.payload;
        },
        getProjectClosureData(state, action) {
            state.projectClosureData = action.payload;
        },
        getProjectApprovalData(state, action) {
            state.projectApprovalData = action.payload;
        },
        setTemplateResponse(state, action) {
            state.templateResponse = action.payload;
        },
        clearTemplateResponse(state) {
            state.templateResponse = {};
        },
        postProjectApprovalForm(state, action) {
            state.projectApprovalForm = action.payload;
        },
        setTemplateId(state, action) {
            state.templateId = action.payload;
        },
        // CLEAR TEMPLATE DATA
        clearTemplateData(state) {
            state.templateData = [];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTemplateList() {
    return async () => {
        try {
            const response = await axios.get(`/manageTemplate`);
            dispatch(slice.actions.setTemplateList(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createTemplateBasicDetails(formData) {
    return async () => {
        try {
            const response = await axios.post(`/addTemplate`, formData);
            dispatch(slice.actions.setTemplateResponse(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addTemplateMappingData(formData) {
    return async () => {
        try {
            const response = await axios.post(`/addTemplate`, formData);
            const { message, success } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.data.message,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    alert: {
                        color: success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            dispatch(slice.actions.setTemplateResponse({ ...response.data, btn: 'submit' }));
            return { message, success };
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function clearTemplateResult() {
    return async () => {
        try {
            dispatch(slice.actions.clearTemplateResponse());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProjectCreationUserData(id) {
    return async () => {
        try {
            const response = await axios.get(`/addPCUserData/${id}`);
            dispatch(slice.actions.getProjectCreationUserData());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function samplePostFunction(data) {
    return async () => {
        try {
            const formData = new FormData();
            Object.keys(data).forEach((fieldName) => {
                if (Object.prototype.hasOwnProperty.call(data, fieldName)) {
                    formData.append(fieldName, data[fieldName]);
                }
            });
            // formData.append('dyn_document', data.dyn_document);
            const response = await axios.post(`/uploadfile`, formData);
            dispatch(slice.actions.getProjectCreationUserData());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postProjectFundingData(formData) {
    return async () => {
        try {
            const response = await axios.get(`/postPFData`, formData);
            dispatch(slice.actions.postProjectFundingData());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProjectExtensionData(id) {
    return async () => {
        try {
            const response = await axios.get(`/getPEData/${id}`);
            dispatch(slice.actions.getProjectExtensionData(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProjectClosureData(id) {
    return async () => {
        try {
            await axios.get(`/getPClData/${id}`);
            dispatch(slice.actions.getProjectClosureData());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProjectApprovalData(id) {
    return async () => {
        try {
            await axios.get(`/getPAData/${id}`);
            dispatch(slice.actions.getProjectApprovalData());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postProjectApprovalForm(formData) {
    return async () => {
        try {
            const response = await axios.get(`/postPAForm`, formData);
            dispatch(slice.actions.postProjectApprovalForm());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function clearTemplateDetails() {
    return async () => {
        dispatch(slice.actions.clearTemplateData());
    };
}

export function activateTemplateVersion(data) {
    return async () => {
        const requestData = { versionID: data };
        const response = await axios.post(`/activateTemplateVersion`, requestData);
        dispatch(slice.actions.setTemplateResponse(response.data));
        dispatch(
            openSnackbar({
                open: true,
                message: response.data.message,
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                variant: 'alert',
                close: false
            })
        );
    };
}

export function getTemplateData(data) {
    return async () => {
        try {
            const { versionID, templateID } = data;
            const response = await axios.get(`/getTemplateData/data?templateID=${templateID}&versionID=${versionID}`);
            dispatch(slice.actions.setTemplateDetails(JSON.parse(response.data.data)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTemplateId(id) {
    return async () => {
        try {
            const response = await axios.post(`/utilisationdata`, 'Vinayaga');
            dispatch(slice.actions.setTemplateId(id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function postTemplateid(data) {
    return async () => {
        try {
            const response = await axios.post(`/utilisationdatapost`, data);
            dispatch(slice.actions.setTemplateId(data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getTemplateutility(back) {
    return async () => {
        try {
            const response = await axios.get(`/templateutil/${back}`);
            dispatch(slice.actions.setTemplateId(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function postTemplatefund(datatag) {
    return async () => {
        try {
            const response = await axios.post(`/Funddatapost`, datatag);
            dispatch(slice.actions.setTemplateId(datatag));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function postTemplatereimbursement(document) {
    return async () => {
        try {
            const response = await axios.post(`/reimbursementdatapost`, document);
            dispatch(slice.actions.setTemplateId(document));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getTemplatefund(backward) {
    return async () => {
        try {
            const response = await axios.get(`/templatefund/${backward}`);
            dispatch(slice.actions.setTemplateId(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTemplatereimbursement(hacker) {
    return async () => {
        try {
            const response = await axios.get(`/templatereimbursement/${hacker}`);
            dispatch(slice.actions.setTemplateId(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteTemplate(data) {
    return async () => {
        try {
            const response = await axios.post(`/removeTemplate`, data);
            const { message, success } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return success;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}
