import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const AuthLogin = Loadable(lazy(() => import('views/main-component/common/login/Index')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword3')));
const ExternalEntry = Loadable(lazy(() => import('views/main-component/common/login/ExternalReviewEntry')));
const UserIndexPage = Loadable(lazy(() => import('../views/main-component/common/user-account/Index')));
const RequestList = Loadable(lazy(() => import('../views/main-component/common/project/RequestListNew')));
const ProjectList = Loadable(lazy(() => import('../views/main-component/common/project/ProjectListNew')));
const ProjectApprovalForm = Loadable(lazy(() => import('views/main-component/end-user/approval-form/ProjectDetails')));
const SubmitExternalReview = Loadable(lazy(() => import('views/main-component/common/project/external-review/ReviewSubmit')));
const ProjectApprovalSubmissionForm = Loadable(lazy(() => import('../views/main-component/end-user/approval-form/SubmitLevel')));
const ProjectQueryForm = Loadable(lazy(() => import('../views/main-component/end-user/approval-form/ViewQuery')));
const ApprovalIndex = Loadable(lazy(() => import('../views/main-component/end-user/approval-form/Index')));
const ProjectNewComponent = Loadable(lazy(() => import('../views/main-component/common/project/index')));
const ProjectInversticator = Loadable(lazy(() => import('../views/main-component/common/project/creation/Investigator')));
const ApprovarExternalReviewRecords = Loadable(
    lazy(() => import('../views/main-component/common/project/external-review/ApproverSubmitedList'))
);
const PurchaseOfficerRequestsList = Loadable(lazy(() => import('../views/main-component/common/account-purchase/purchase/list')));
const PurchaseOfficerRequest = Loadable(lazy(() => import('../views/main-component/common/account-purchase/purchase/index')));
const AccountOfficerRequest = Loadable(lazy(() => import('../views/main-component/common/account-purchase/account/index')));
const ProjectActivity = Loadable(lazy(() => import('../views/main-component/common/project/ProjectActivity')));
const PurchaseOfficerRequestsListNew = Loadable(lazy(() => import('../views/main-component/common/account-purchase/purchase/ListNew')));
const AccountOfficerRequestsListNew = Loadable(lazy(() => import('../views/main-component/common/account-purchase/account/ListNew')));

// committee related modules
const Committee = Loadable(lazy(() => import('../views/main-component/common/project-committee/index')));
const CommitteeList = Loadable(lazy(() => import('../views/main-component/common/project-committee/List')));

// notification related modules
const Notification = Loadable(lazy(() => import('views/main-component/common/notification/Index')));

const PiDashboard = Loadable(lazy(() => import('views/main-component/common/dashboard/Index')));
const TestHistoryCardUI = Loadable(lazy(() => import('../views/main-component/common/test-pages/HistoryCard')));

// ==============================|| MAIN ROUTING ||============================== //

const EndUserRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/externalLogin',
            element: <ExternalEntry />
        },
        {
            path: '/userIndex',
            element: <UserIndexPage />
        },
        {
            path: '/myProject',
            element: <ProjectList />
        },
        {
            path: '/otherProject',
            element: <RequestList />
        },
        {
            path: '/projaprForm',
            element: <ProjectApprovalForm />
        },
        {
            path: '/Project',
            element: <ProjectNewComponent />
        },
        {
            path: '/viewProject',
            element: <ProjectNewComponent />
        },
        {
            path: '/queryForm',
            element: <ProjectQueryForm />
        },
        {
            path: '/projaprsubForm',
            element: <ProjectApprovalSubmissionForm />
        },
        {
            path: '/submitExternalReview',
            element: <SubmitExternalReview />
        },
        {
            path: '/approvarExternalReviewRecords',
            element: <ApprovarExternalReviewRecords />
        },
        {
            path: '/aprindex/:aprindexID',
            element: <ApprovalIndex />
        },
        {
            path: '/ProjectInversticator/:id',
            element: <ProjectInversticator />
        },
        {
            path: '/purchase-list',
            element: <PurchaseOfficerRequestsListNew />
        },
        {
            path: '/purchase-request',
            element: <PurchaseOfficerRequest />
        },
        {
            path: '/account-list',
            element: <AccountOfficerRequestsListNew />
        },
        {
            path: '/fund-request',
            element: <AccountOfficerRequest />
        },
        {
            path: '/committee',
            element: <Committee />
        },
        {
            path: '/committee/:versionID',
            element: <Committee />
        },
        {
            path: '/committeeList',
            element: <CommitteeList />
        },
        {
            path: '/projectActivity',
            element: <ProjectActivity />
        },
        {
            path: '/notification',
            element: <Notification />
        },
        {
            path: '/dashboard',
            element: <PiDashboard />
        },
        {
            path: '/test-page',
            element: <TestHistoryCardUI />
        }
    ]
};

export default EndUserRoutes;
