// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from './snackbar';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    workflowList: [],
    versionData: [],
    workflowCrudResponse: [],
    versionUpdateResponse: [],
    instDeptMapData: [],
    updateData: '',
    mappingRes: [],
    workflowMappingData: [],
    formListData: [],
    staticFormListData: [],
    allTypeWorkflowList: [],
    defaultWorkflowList: []
};

const slice = createSlice({
    name: 'workflow',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // SET  LIST
        setWorkflowList(state, action) {
            state.workflowList = action.payload;
        },

        // SET  version data
        setVersionData(state, action) {
            state.versionData = action.payload;
        },

        setWorkflowCrudResponse(state, action) {
            state.workflowCrudResponse = action.payload;
        },
        setVersionUpdateRes(state, action) {
            state.versionUpdateResponse = action.payload;
        },
        setInstDeptMapData(state, action) {
            state.instDeptMapData = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        setMappingResponse(state, action) {
            state.mappingRes = action.payload;
        },
        setWorkflowMappingData(state, action) {
            state.workflowMappingData = action.payload;
        },
        setformList(state, action) {
            state.formListData = action.payload;
        },
        setStaticFormList(state, action) {
            state.staticFormListData = action.payload;
        },
        setAllTypeWorkflowList(state, action) {
            state.allTypeWorkflowList = action.payload;
        },
        setDefaultWorkflowList(state, action) {
            state.defaultWorkflowList = action.payload;
        },
        clearWorkflowData(state) {
            state.error = null;
            state.versionData = [];
            state.workflowCrudResponse = [];
            state.versionUpdateResponse = [];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function clearWorkflowDetails() {
    return async () => {
        dispatch(slice.actions.clearWorkflowData());
    };
}

export function flowListCount({ searchTerm = null, filterStatus = null, type } = {}) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.get(`/flowListCount?searchTerm=${searchTerm}&filterStatus=${filterStatus}&type=${type}`);
            const { data } = response;
            return data?.result;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return 0;
        }
    };
}

export function getWorkflowList({ offset = 0, limit = 10, searchTerm = null, filterStatus = null, type } = {}) {
    return async () => {
        try {
            const response = await axios.get(`/manageWorkflow`, {
                params: { offset, limit, searchTerm, filterStatus, type }
            });
            const [firstTransaction = ''] = response?.data?.data || [];
            const workflowList = JSON.parse(firstTransaction?.result);
            dispatch(slice.actions.setWorkflowList(response.data));
            return workflowList || [];
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return [];
        }
    };
}

export function getVersionData(workflowID, versionID) {
    return async () => {
        try {
            if (workflowID !== undefined && versionID !== undefined) {
                const response = await axios.get(`/getWorkflowData/data?workflowID=${workflowID}&versionID=${versionID}`);
                dispatch(slice.actions.setVersionData(JSON.parse(response.data.data)));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// export function removeLevelFun(id) {
//     return async () => {
//         try {
//             const response = await axios.post(`/removeLevel`, { id });
//             dispatch(slice.actions.setWorkflowCrudResponse({ ...response.data, operation: 'd' }));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

export function crudWorkflowData(formData) {
    return async () => {
        try {
            const response = await axios.post(`/addWorkflow`, formData);
            const { message, success } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    message,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            dispatch(slice.actions.setWorkflowCrudResponse(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function deleteVersionAPI(data) {
    return async () => {
        try {
            console.log('Step 3');
            const response = await axios.post(`/removeVersion`, { id: data.id, type: data.headType });
            const { message, success, type } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: success ? 'success' : 'error'
                    },
                    close: false
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function updateVersionData(versionID, refNumber) {
    return async () => {
        try {
            const response = await axios.post(`/updateVersion/data?versionID=${versionID}`);
            const { message, success } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    message: `${refNumber} ${message}`,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            dispatch(slice.actions.setVersionUpdateRes(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInstDeptMapData() {
    return async () => {
        try {
            const response = await axios.get(`/getAllInstDeptMap`);
            dispatch(slice.actions.setInstDeptMapData(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setUpdateData(data) {
    return (dispatch) => {
        dispatch(slice.actions.setUpdateData(data));
    };
}

export function getWorkflowMappingDetails(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getWorkflowMappingData/${id}`);
            const modifiedData = response.data.mappingData.map((item) => ({
                ...item,
                modification: false,
                deleted: false
            }));
            dispatch(slice.actions.setWorkflowMappingData(modifiedData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function submitMappingDetails(data, id) {
    return async (dispatch) => {
        try {
            const updatedValue = data.filter(
                (obj) => Object.prototype.hasOwnProperty.call(obj, 'id') && obj.modification === true && obj.deleted !== true
            );
            const insertedValue = data.filter(
                (obj) => !Object.prototype.hasOwnProperty.call(obj, 'id') && obj.modification === true && obj.deleted !== true
            );
            const deletedValue = data.filter((obj) => Object.prototype.hasOwnProperty.call(obj, 'id') && obj.deleted === true);
            const idValues = deletedValue.map((obj) => parseInt(obj.id, 10));
            const allData = {
                insert: insertedValue,
                update: updatedValue,
                delete: idValues,
                id
            };

            const response = await axios.post(`/managingWorkflowMapping`, allData);
            const { message, success } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    message,
                    alert: {
                        color: success ? 'success' : 'error',
                        variant: 'filled'
                    },
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    close: false
                })
            );
            dispatch(slice.actions.setMappingResponse(response.data));
            dispatch(getWorkflowMappingDetails(id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFormList() {
    return async () => {
        try {
            const response = await axios.get(`/getFormList`);
            dispatch(slice.actions.setformList(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// export function getAllTypeWorkFlowList() {
//     return async () => {
//         try {
//             const response = await axios.get(`/getAllTypeWorkFlowList`);
//             const { data } = response || {};
//             const { result = [] } = data;
//             dispatch(slice.actions.setAllTypeWorkflowList(result));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// export function getStaticFormList() {
//     return async () => {
//         try {
//             const response = await axios.get(`/getStaticFormList`);
//             dispatch(slice.actions.setStaticFormList(response.data));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

export function setDefaultWorkflow(data) {
    return async () => {
        try {
            const response = await axios.post(`/setDefaultWorkflow`, data);
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}
export function getDefaultWorkflow() {
    return async () => {
        try {
            const response = await axios.get(`/getDefaultWorkflowList`);
            const { data } = response;
            dispatch(slice.actions.setDefaultWorkflowList(data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteWorkflowThroughInst(workflowID, mappingID) {
    return async (dispatch) => {
        try {
            const allData = {
                insert: [],
                update: [],
                delete: [mappingID],
                id: workflowID
            };
            const response = await axios.post(`/deleteWorkflowMappingInstitution`, allData);
            const { message, success } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    message,
                    alert: {
                        color: success ? 'success' : 'error',
                        variant: 'filled'
                    },
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    close: false
                })
            );
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false, type: 'error', message: 'error is occur' };
        }
    };
}
