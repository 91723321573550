export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return undefined; // Explicitly return undefined if the cookie is not found
};

export const setCookie = (name, value, hours) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + hours * 60 * 60 * 1000); // Convert hours to milliseconds
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
};
