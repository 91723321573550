import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const UserListComponent = Loadable(lazy(() => import('views/main-component/admin/user-management/UserManageNew')));
const MasterListComponent = Loadable(lazy(() => import('views/main-component/admin/master-management/MasterManage')));
const MasterStaticFormComponent = Loadable(lazy(() => import('views/main-component/admin/master-management/ManageStaticForm')));
const MasterDetailsComponent = Loadable(lazy(() => import('views/main-component/admin/master-management/Index')));
const MasterRole = Loadable(lazy(() => import('views/main-component/admin/master-management/Role/index')));
const TypeDetailsComponent = Loadable(lazy(() => import('views/main-component/admin/master-management/AddType')));
const AppUserAccountProfile1 = Loadable(lazy(() => import('views/main-component/admin/user-management/Index')));

const TemplateCRUD = Loadable(lazy(() => import('views/main-component/admin/template-management/Index')));
const TemplateCRUDNew = Loadable(lazy(() => import('views/main-component/admin/template-management/NewFieldComponent')));

const TemplateList = Loadable(lazy(() => import('views/main-component/admin/template-management/TemplateList')));

const SamplePagess = Loadable(lazy(() => import('views/sample-page/index')));
const NewWorkflowComponent = Loadable(lazy(() => import('views/main-component/admin/workflow-management/NewWorkflow')));
const WorkflowList = Loadable(lazy(() => import('views/main-component/admin/workflow-management/ListNew')));
const TemplateListNew = Loadable(lazy(() => import('views/main-component/admin/template-management/ListNew')));
const WorkflowIndex = Loadable(lazy(() => import('views/main-component/admin/workflow-management/Index')));
const SampleTemp = Loadable(lazy(() => import('views/main-component/admin/template-management/dynamic-pages/GenerateFields')));
const WorkflowMapping = Loadable(lazy(() => import('views/main-component/admin/workflow-management/WorkflowMapping')));
const DefaultWorkflowMapping = Loadable(lazy(() => import('views/main-component/admin/workflow-management/DefaultList')));
const UserIndexPage = Loadable(lazy(() => import('../views/main-component/common/user-account/Index')));
const UserPassword = Loadable(lazy(() => import('../views/main-component/common/user-account/PasswordForm')));
const UserProfilePage = Loadable(lazy(() => import('../views/main-component/common/user-account/ProfileForm')));
const UserMappingPage = Loadable(lazy(() => import('../views/main-component/common/user-account/MappingForm')));

const ProfileComponent = Loadable(lazy(() => import('views/main-component/admin/form/profile')));
const Form8Component = Loadable(lazy(() => import('views/main-component/admin/form/UtilizationForm')));
const InstituteComponent = Loadable(lazy(() => import('views/main-component/admin/form/institute')));
const UserFormComponent = Loadable(lazy(() => import('views/main-component/admin/master-management/InstituteMaster')));
const InstituteTableComponent = Loadable(lazy(() => import('views/common-component/table/InstituteTables')));
const SystemUserDashboard = Loadable(lazy(() => import('../views/main-component/common/dashboard/Index')));

const DynamicTable = Loadable(lazy(() => import('../views/common-component/table/DynamicTable')));
const WorkflowForm = Loadable(lazy(() => import('../views/main-component/admin/workflow-management/Form')));
// static pages

const TestRatingComopnent = Loadable(lazy(() => import('../views/common-component/input-fields/fields/Rating')));
const TypeGroupCard = Loadable(lazy(() => import('../views/main-component/admin/master-management/TypeGroupList')));
const InstitutionMapping = Loadable(lazy(() => import('../views/main-component/admin/master-management/UpdateInst')));
const WorkFlowNewDetails = Loadable(lazy(() => import('../views/main-component/admin/workflow-management/Levels')));
const TypeList = Loadable(lazy(() => import('../views/main-component/admin/master-management/ManageType')));
const TemplateIndex = Loadable(lazy(() => import('../views/main-component/admin/template-management/NewIndexV2')));

const TaskComponent = Loadable(lazy(() => import('views/common-component/others/TasksCard')));

const BulkUploadComponent = Loadable(lazy(() => import('../views/main-component/admin/bulk-upload/Index')));
const ProjectApprovalForm = Loadable(lazy(() => import('views/main-component/end-user/approval-form/ProjectDetails')));
const ProjectApprovalSubmissionForm = Loadable(lazy(() => import('../views/main-component/end-user/approval-form/SubmitLevel')));
const ProjectQueryForm = Loadable(lazy(() => import('../views/main-component/end-user/approval-form/ViewQuery')));
const ApprovalIndex = Loadable(lazy(() => import('../views/main-component/end-user/approval-form/Index')));
const ProjectInversticator = Loadable(lazy(() => import('../views/main-component/common/project/creation/Investigator')));
const BudgetDataComponent = Loadable(lazy(() => import('views/common-component/table/BudgetTable')));
const MilestoneComponent = Loadable(lazy(() => import('../views/main-component/common/project/creation/Milestones')));
const ProjectCreationComponent = Loadable(lazy(() => import('../views/main-component/common/project/creation/BasicInitiation')));
const ProjectInfrastructureComponent = Loadable(lazy(() => import('../views/main-component/common/project/creation/Infrastructure')));
// project basic details
const ProjectBasicDetailsComponent = Loadable(lazy(() => import('../views/main-component/common/project/creation/BasicDetails')));
const ProjectBudgetComponent = Loadable(lazy(() => import('../views/main-component/common/project/creation/Budget')));
const ProjectDeliverablesComponent = Loadable(lazy(() => import('../views/main-component/common/project/creation/Deliverables')));
const ProjectMilestoneComponent = Loadable(lazy(() => import('../views/main-component/common/project/creation/TimelineAndMilestone')));
const PiDashboard = Loadable(lazy(() => import('views/main-component/common/dashboard/Index')));
const Notification = Loadable(lazy(() => import('views/main-component/common/notification/Index')));
const ManageDepartment = Loadable(lazy(() => import('views/main-component/admin/master-management/ManageDepartment')));
const ManageFundingAgency = Loadable(lazy(() => import('views/main-component/admin/master-management/ManageFundingAgency')));
const ManageProfileRole = Loadable(lazy(() => import('views/main-component/admin/master-management/ManageRole')));
const ManageInstitution = Loadable(lazy(() => import('views/main-component/admin/master-management/ManageInstitution')));
const ManageBudgetHead = Loadable(lazy(() => import('views/main-component/admin/master-management/ManageBudgetHeads')));
const ManageType = Loadable(lazy(() => import('views/main-component/admin/master-management/ManageTypeNew')));
const Committee = Loadable(lazy(() => import('../views/main-component/common/project-committee/index')));
const CommitteeList = Loadable(lazy(() => import('../views/main-component/common/project-committee/List')));

// external review modules
const ExternalReviewerProjectList = Loadable(
    lazy(() => import('../views/main-component/common/project/external-review/externalReviewerProjectList'))
);

const ChartComponent = Loadable(lazy(() => import('../views/common-component/others/GanttChart/index')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/userList',
            element: <UserListComponent />
        },

        {
            path: '/addUser',
            element: <AppUserAccountProfile1 />
        },
        {
            path: '/userDetails/:refNumber',
            element: <AppUserAccountProfile1 />
        },
        {
            path: '/Manage/:type',
            element: <MasterDetailsComponent type="department" />
        },
        {
            path: 'masterDetails/:type/:ids',
            element: <MasterDetailsComponent type="department" />
        },
        {
            path: 'Role/:id',
            element: <MasterRole mode="Edit" />
        },
        {
            path: 'Role',
            element: <MasterRole mode="New" />
        },
        {
            path: '/masterList',
            element: <MasterListComponent />
        },
        {
            path: '/m-static',
            element: <MasterStaticFormComponent />
        },
        {
            path: '/addMaster/:type',
            element: <MasterDetailsComponent type="department" />
        },
        {
            path: '/addMaster/:type',
            element: <MasterDetailsComponent type="institution" />
        },
        {
            path: '/addMaster/:type',
            element: <MasterDetailsComponent type="profileRole" />
        },
        {
            path: '/addMaster/:type',
            element: <MasterDetailsComponent type="fundingAgency" />
        },
        {
            path: '/addMaster/:type',
            element: <MasterDetailsComponent type="budgetHead" />
        },
        {
            path: '/typeDetails/:type/:group/:id',
            element: <TypeDetailsComponent type="type" />
        },
        {
            path: '/typeDetails/:type/:group',
            element: <TypeDetailsComponent type="type" />
        },
        // {
        //     path: '/masterList/:type',
        //     element: <MasterListComponent type="department" />
        // },
        {
            path: '/masterList/:type',
            element: <MasterListComponent type="institution" />
        },
        // {
        //     path: '/masterList/:type',
        //     element: <MasterListComponent type="profileRole" />
        // },
        // {
        //     path: '/masterList/:type',
        //     element: <MasterListComponent type="fundingAgency" />
        // },
        // {
        //     path: '/masterList/:type',
        //     element: <MasterListComponent type="budgetHead" />
        // },
        {
            path: '/typeGroupList/:type',
            element: <TypeGroupCard type="typeGroup" />
        },
        {
            path: '/typeList/:group',
            element: <ManageType />
        },
        {
            path: '/newWorkflow',
            // element: <NewWorkflowComponent />
            element: <WorkFlowNewDetails />
        },
        {
            path: '/templateManage',
            element: <TemplateListNew />
        },
        {
            path: '/templateNew',
            element: <TemplateCRUDNew />
        },
        {
            path: '/addNewVersionTemplate/:templateID',
            element: <TemplateCRUD />
        },
        {
            path: '/viewVersionTemplate/:templateID/:versionID',
            element: <TemplateIndex />
        },
        {
            path: '/addTemplate',
            element: <TemplateCRUDNew />
        },
        {
            path: '/form8',
            element: <Form8Component />
        },
        // {
        //     path: '/Manage/Institutes',
        //     element: <UserFormComponent />
        // },
        {
            path: '/institutetable',
            element: <InstituteTableComponent />
        },
        {
            path: '/institute',
            element: <InstituteComponent />
        },
        {
            path: '/profile',
            element: <ProfileComponent />
        },
        {
            path: '/autocomplet',
            element: <SamplePagess />
        },

        {
            path: '/workflowManage',
            element: <WorkflowList />
        },
        {
            path: '/TemplateListNew',
            element: <TemplateListNew />
        },
        {
            path: '/defaultWorkflow',
            element: <DefaultWorkflowMapping />
        },
        {
            path: '/viewVersionWorkflow/:workflowID/:versionID',
            element: <NewWorkflowComponent />
        },
        {
            path: '/userIndex',
            element: <UserIndexPage />
        },
        {
            path: '/addNewVersionWorkflow/:workflowID/:versionID',
            element: <NewWorkflowComponent />
        },
        {
            path: '/sample',
            element: <SampleTemp />
        },
        {
            path: '/addWorkflowMapping/:workflowID',
            element: <WorkflowMapping />
        },
        {
            path: '/userProfile',
            element: <UserProfilePage />
        },
        {
            path: '/userPassword',
            element: <UserPassword />
        },
        {
            path: '/sysUserDashboard',
            element: <SystemUserDashboard />
        },
        {
            path: '/userMap/:userMapID',
            element: <UserMappingPage />
        },

        {
            path: '/dynamicTable',
            element: <DynamicTable />
        },

        {
            path: '/updateInst/:type',
            element: <InstitutionMapping type="institution" />
        },
        {
            path: '/newWorkFlowDetails',
            element: <WorkflowIndex />
        },
        {
            path: '/newWorkFlowDetails/:workflowID/:versionID',
            element: <WorkflowIndex />
        },
        {
            path: '/wform',
            element: <WorkflowForm />
        },
        {
            path: 'addTemplateIndex',
            element: <TemplateIndex />
        },
        {
            path: 'wf2',
            element: <WorkflowIndex />
        },
        {
            path: 'rc',
            element: <TestRatingComopnent />
        },

        {
            path: '/task',
            element: <TaskComponent />
        },

        {
            path: '/bulkUpload',
            element: <BulkUploadComponent />
        },

        {
            path: '/ProjectInversticator/:id',
            element: <ProjectInversticator />
        },
        {
            path: '/projectInfra',
            element: <ProjectInfrastructureComponent />
        },
        {
            path: '/projectBudget',
            element: <ProjectBudgetComponent />
        },
        {
            path: '/projectDeliverables',
            element: <ProjectDeliverablesComponent />
        },
        {
            path: '/budgetData',
            element: <BudgetDataComponent />
        },
        {
            path: '/milestone',
            element: <MilestoneComponent />
        },
        {
            path: '/ProjectNew',
            element: <ProjectCreationComponent />
        },
        {
            path: '/ProjectBasic',
            element: <ProjectBasicDetailsComponent />
        },
        {
            path: '/ProjectMilestone',
            element: <ProjectMilestoneComponent />
        },

        {
            path: '/projaprForm',
            element: <ProjectApprovalForm />
        },
        {
            path: '/queryForm',
            element: <ProjectQueryForm />
        },

        {
            path: '/aprindex/:aprindexID',
            element: <ApprovalIndex />
        },
        {
            path: '/projaprsubForm',
            element: <ProjectApprovalSubmissionForm />
        },
        {
            path: '/testChart',
            element: <ChartComponent />
        },
        {
            path: '/dashboard',
            element: <PiDashboard />
        },
        {
            path: '/notification',
            element: <Notification />
        },
        {
            path: '/masterList/department',
            element: <ManageDepartment />
        },
        {
            path: '/masterList/fundingAgency',
            element: <ManageFundingAgency />
        },
        {
            path: '/masterList/profileRole',
            element: <ManageProfileRole />
        },
        {
            path: '/Manage/Institutes',
            element: <ManageInstitution />
        },
        {
            path: '/masterList/budgetHead',
            element: <ManageBudgetHead />
        },
        {
            path: '/committeeList',
            element: <CommitteeList />
        },
        {
            path: '/committee',
            element: <Committee />
        },
        {
            path: '/committee/:versionID',
            element: <Committee />
        }
    ]
};

export default MainRoutes;
