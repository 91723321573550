import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn, userType } = useAuth();
    const navigate = useNavigate();
    let path = null;
    const location = useLocation();
    switch (userType) {
        case 'Admin User':
            path = '/userIndex';
            break;
        case 'External User':
            path = '/ExternalReviewerProjectList';
            break;
        default:
            path = DASHBOARD_PATH;
            break;
    }
    if (isLoggedIn && location?.pathname === '/externalLogin' && location.search) {
        path = `/externalLogin${location.search}`;
    }
    useEffect(() => {
        if (isLoggedIn) {
            navigate(path, { replace: true });
        }
    }, [isLoggedIn, navigate, userType]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
