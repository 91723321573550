// third-party
import { FormattedMessage } from 'react-intl';

import { IconBrandChrome, IconHelp, IconSitemap, IconUsers, IconTemplate } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconTemplate
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'sample-docs-roadmaps',
    type: 'group',
    children: [
        {
            id: 'External Review',
            title: <FormattedMessage id="external-review-head" />,
            type: 'collapse',
            icon: icons.IconTools,
            children: [
                {
                    id: 'external-reviewer-projects',
                    title: <FormattedMessage id="external-reviewer-projects" />,
                    type: 'item',
                    url: '/ExternalReviewerProjectList'
                }
            ]
        }
    ]
};

export default pages;
