import other from './other';
import pages from './pages';
import external from './externalUser';
import useAuth from 'hooks/useAuth';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

// ==============================|| MENU ITEMS ||============================== //

const approverList = {
    id: 'approval-project',
    title: <FormattedMessage id="approval-project" />,
    type: 'item',
    url: '/otherProject'
};
const accountList = {
    id: 'account-list',
    title: <FormattedMessage id="account-list" />,
    type: 'item',
    url: '/account-list'
};
const purchaseList = {
    id: 'purchase-list',
    title: <FormattedMessage id="purchase-list" />,
    type: 'item',
    url: '/purchase-list'
};
const externalReviewList = {
    id: 'external-review-head',
    title: <FormattedMessage id="external-review-head" />,
    type: 'collapse',
    children: [
        {
            id: 'submit-review',
            title: <FormattedMessage id="submit-review" />,
            type: 'item',
            url: '/submitExternalReview'
        },
        {
            id: 'view-external-review',
            title: <FormattedMessage id="view-external-review" />,
            type: 'item',
            url: '/approvarExternalReviewRecords'
        }
    ]
};

// Utility function to add items to 'research' children
const addToResearchChildren = (pages, itemsToAdd) => {
    const research = pages.children.find((child) => child.id === 'research');
    if (research) {
        itemsToAdd.forEach((item) => {
            if (!research.children.some((child) => child.id === item.id)) {
                research.children.push(item);
            }
        });
    }
};

const MenuItems = () => {
    const { tabVisibility, user } = useAuth();
    const [userType, setUserType] = useState('');

    useEffect(() => {
        if (user) {
            setUserType(user.userType);
        }
    }, [user]);

    const itemsToAdd = [];
    if (tabVisibility?.externalReview) itemsToAdd.push(externalReviewList);
    if (tabVisibility?.approverList) itemsToAdd.push(approverList);
    if (tabVisibility?.fundList) itemsToAdd.push(accountList);
    if (tabVisibility?.purchaseList) itemsToAdd.push(purchaseList);

    if (itemsToAdd.length > 0) {
        addToResearchChildren(pages, itemsToAdd);
    }

    const tabsVisibility = {
        'Super Administrator': [other],
        'Admin User': [other],
        'System User': [pages],
        'External User': [external]
    };

    return { menuItem: { items: tabsVisibility[userType] || [] } };
};

export default MenuItems;
