// third-party
import { FormattedMessage } from 'react-intl';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconUsers, IconTemplate, IconTools, IconChartDots } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconTemplate,
    IconTools,
    IconChartDots
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs',
    type: 'group',
    children: [
        {
            id: 'Dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: DashboardIcon,
            breadcrumbs: true
        },
        {
            id: 'Manage',
            title: <FormattedMessage id="manage" />,
            type: 'collapse',
            icon: SettingsIcon,
            children: [
                {
                    id: 'instituteform',
                    title: <FormattedMessage id="institution-list" />,
                    type: 'item',
                    url: '/Manage/Institutes'
                },
                {
                    id: 'department',
                    title: <FormattedMessage id="department-list" />,
                    type: 'item',
                    url: '/masterList/department'
                },
                {
                    id: 'userList',
                    title: <FormattedMessage id="user-management" />,
                    type: 'item',
                    url: '/userList'
                },
                {
                    id: 'profileRole',
                    title: <FormattedMessage id="profileRole-list" />,
                    type: 'item',
                    url: '/masterList/profileRole'
                }
            ]
        },
        {
            id: 'rpmo',
            title: <FormattedMessage id="rpmo" />,
            type: 'collapse',
            icon: AccountTreeIcon,
            children: [
                {
                    id: 'workflowManage',
                    title: <FormattedMessage id="workflow-management" />,
                    type: 'item',
                    url: '/workflowManage'
                },
                {
                    id: 'templateManage',
                    title: <FormattedMessage id="template-management" />,
                    type: 'item',
                    url: '/templateManage'
                },
                {
                    id: 'budgetHead',
                    title: <FormattedMessage id="budget" />,
                    type: 'item',
                    url: '/masterList/budgetHead'
                },
                {
                    id: 'fundingAgency',
                    title: <FormattedMessage id="funding" />,
                    type: 'item',
                    url: '/masterList/fundingAgency'
                },
                {
                    id: 'typeGroup',
                    title: <FormattedMessage id="enumerations" />,
                    type: 'item',
                    url: '/typeGroupList/typeGroup'
                }
            ]
        }
        // {
        //     id: 'research',
        //     title: <FormattedMessage id="research" />,
        //     type: 'collapse',
        //     icon: icons.IconTools,
        //     children: [
        //         {
        //             id: 'projectList',
        //             title: <FormattedMessage id="project-list" />,
        //             type: 'item',
        //             url: '/projectList'
        //         },
        //         {
        //             id: 'externalReview',
        //             title: <FormattedMessage id="externalReview" />,
        //             type: 'item'
        //         },
        //         {
        //             id: 'queries',
        //             title: <FormattedMessage id="queries" />,
        //             type: 'item'
        //         },
        //         {
        //             id: 'meetings',
        //             title: <FormattedMessage id="meetings" />,
        //             type: 'item'
        //         },
        //         {
        //             id: 'utilizationCertificate',
        //             title: <FormattedMessage id="utilizationCertificate" />,
        //             type: 'item'
        //         },
        //         {
        //             id: 'purchase',
        //             title: <FormattedMessage id="purchase" />,
        //             type: 'item'
        //         },
        //         {
        //             id: 'accounts',
        //             title: <FormattedMessage id="accounts" />,
        //             type: 'item'
        //         },
        //         {
        //             id: 'outcomes',
        //             title: <FormattedMessage id="outcomes" />,
        //             type: 'item'
        //         }
        //     ]
        // },
        // {
        //     id: 'report',
        //     title: <FormattedMessage id="report" />,
        //     type: 'collapse',
        //     icon: icons.IconChartDots,
        //     children: [
        //         {
        //             id: 'status',
        //             title: <FormattedMessage id="status" />,
        //             type: 'item'
        //         },
        //         {
        //             id: 'utilizationReport',
        //             title: <FormattedMessage id="utilizationReport" />,
        //             type: 'item'
        //         }
        //     ]
        // }
    ]
};

export default other;
