// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
// ----------------------------------------------------------------------

const initialState = {
    error: null,
    allNotificationList: []
};

const slice = createSlice({
    name: 'others',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        setNotificationList(state, action) {
            state.allNotificationList = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNotification() {
    return async () => {
        try {
            const result = await axios.get(`/getNotification`);
            const { data = {} } = result || {};
            const { records = [] } = data || {};
            dispatch(slice.actions.setNotificationList(records));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDocument(filename) {
    return async () => {
        try {
            const response = await axios.get(`/getDocument/${filename}`, {
                responseType: 'blob' // Set the response type to blob
            });
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}
