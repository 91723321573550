import { Button, CardActions, CardContent, Grid, Typography, Box, Avatar } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import WatchLaterTwoToneIcon from '@mui/icons-material/WatchLaterTwoTone';
import { statusColors, convertToCustomFormat } from 'utils/util';
import { useState } from 'react';
import { now } from 'lodash';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { maxWidth } from '@mui/system';
import {
    AttachMoney,
    PlayCircleOutline,
    AddCircleOutline,
    NotificationsNone,
    RestartAlt,
    KeyOff,
    CancelPresentation
} from '@mui/icons-material';

const NotificationUi = ({ data, handleClose }) => {
    const navigate = useNavigate();

    const notificationICON = {
        PC: <AddCircleOutline sx={{ fontSize: '1.25rem' }} />,
        'Fund/Purchase Request_RC': <AttachMoney sx={{ fontSize: '1.25rem' }} />,
        default: <NotificationsNone sx={{ fontSize: '1.25rem' }} />,
        'ReImbursement Request_RC': <RestartAlt sx={{ fontSize: '1.25rem' }} />,
        'Unlock Request_RC': <KeyOff sx={{ fontSize: '1.25rem' }} />,
        'Closure Request_RC': <CancelPresentation sx={{ fontSize: '1.25rem' }} />,
        'Initiation Request_RC': <PlayCircleOutline sx={{ fontSize: '1.25rem' }} />
    };

    const iconSX = {
        fontSize: '1rem',
        marginRight: '8px',
        verticalAlign: 'middle'
    };

    const [readStatus, setReadStatus] = useState({});

    const toggleReadStatus = (id) => {
        setReadStatus((prevStatus) => ({
            ...prevStatus,
            [id]: !prevStatus[id]
        }));
    };

    const getTimeAgo = (date) => {
        const currentTime = new Date().getTime(); // Get current timestamp
        const tdate = new Date(date); // Convert UTC time string to Date object

        // Get UTC time in milliseconds
        const utcTimeMillis = tdate.getTime();

        // Offset for IST in milliseconds (UTC+5:30)
        const offsetMillis = 5.5 * 60 * 60 * 1000;

        // Calculate IST time in milliseconds
        const istTimeMillis = utcTimeMillis + offsetMillis;

        // Create a new Date object with IST time
        const notificationDate = new Date(istTimeMillis).getTime();

        const difference = currentTime - notificationDate;

        const seconds = Math.floor(difference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
        if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    };
    const handleViewAllNotifications = () => {
        navigate('/notification');
        handleClose();
    };

    return (
        <MainCard title="Notifications" content={false} sx={{ boxShadow: 3, maxWidth: '400px' }}>
            <CardContent sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Grid container spacing={gridSpacing}>
                    {data.map((item, index) => (
                        <Grid item xs={12} key={item.id || index}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    borderBottom: '1px solid #f0f0f0',
                                    py: 2
                                }}
                                onClick={() => toggleReadStatus(item.id)}
                            >
                                <Avatar sx={{ bgcolor: statusColors[readStatus[item.id] ? 'success' : 'error'] }}>
                                    {notificationICON[item?.catagory || 'default']}
                                </Avatar>
                                <Box sx={{ ml: 2, flexGrow: 1 }}>
                                    <Typography variant="subtitle1" fontWeight={readStatus[item.id] ? 'normal' : 'bold'}>
                                        {item.subject}
                                    </Typography>
                                    <Typography variant="subtitle2">{item.content}</Typography>
                                </Box>
                                <Typography variant="caption" color="text.secondary" sx={{ marginLeft: '30px' }}>
                                    {getTimeAgo(item.createdDate)}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button variant="text" size="small" onClick={handleViewAllNotifications}>
                    View all Notifications
                </Button>
            </CardActions>
        </MainCard>
    );
};

export default NotificationUi;
