// third-party
import { FormattedMessage } from 'react-intl';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddTaskIcon from '@mui/icons-material/AddTask';
import RateReviewIcon from '@mui/icons-material/RateReview';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import BiotechIcon from '@mui/icons-material/Biotech';

import { IconBrandChrome, IconHelp, IconSitemap, IconUsers, IconTemplate } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconTemplate
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'sample-docs-roadmaps',
    type: 'group',
    children: [
        {
            id: 'Dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: DashboardIcon,
            breadcrumbs: true
        },
        {
            id: 'research',
            title: <FormattedMessage id="research" />,
            type: 'collapse',
            icon: BiotechIcon,
            children: [
                {
                    id: 'my-projects',
                    title: <FormattedMessage id="my-projects" />,
                    type: 'item',
                    url: '/myProject'
                },
                {
                    id: 'committee',
                    title: <FormattedMessage id="committee" />,
                    type: 'item',
                    url: '/committeeList'
                }
            ]
        }
    ]
};

export default pages;
